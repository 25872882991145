export const topBarHeight = 45
export const sideNavWidth = 220
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

// localhost
// export const base_url = 'http://localhost:3000/api/'
// live
// export const base_url = 'http://starxpress.online/api/'
// export const base_url = 'http://shokolsheba.com/api/'


let base_url = "http://localhost:3000/api/" // lets local default
// check the environment
if(process.env.NODE_ENV === "development"){
    base_url = "http://localhost:3000/api/"
}
if(process.env.NODE_ENV === "production"){
    base_url = "https://courier.cadburyaffiliate.com/api/"
}
export {base_url};


export const business_name ='FLYBEE'
export const business_address ='House-90, Road-11, Monsurabad Rasidential Area, Dhaka'
export const business_email ='info.flybeecourier@gmail.com'
export const business_web ='www.flybeecourier.com'
export const business_hotline ='+88 0187-7298355'
// content
export const footer_copyrights_text_right = ''
export const footer_copyrights_text_left =  'Copyrights © 2024 All Rights Reserved by Starsolutions';
export const site_name='FLYBEE';
 